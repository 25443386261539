// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Oswald:wght@300;400;500;600&family=Pixelify+Sans:wght@400;500;600;700&display=swap');


@primary-color: #3F8EFF;
@secondary-color: #20AF24;
@black: #000;
@white: #fff;
@grey: #757575;
@lite_grey: #D6D6D6;
@text_dark_black: #2e2d2c;
@text_light_black: #23211e;
@action_btn_color: #696b71;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@label-color: #757575;
@input-bg: #fff;
@input-border: rgba(117, 117, 117, 0.5);
@primary-font: "Poppins", sans-serif;
@inter-font: 'Inter', sans-serif;
@input-border-hover: #4096ff;
@language-dropdown:#f47216;

@gutter: 24px;
@text-color: #494949;

// Main Header Variables
@layout-body-background: #f7f7f7;
@layout-header-background: @white;
@layout-header-color: @text-color;
@layout-sidebar-background: @primary-color;
@layout-header-height: 125px;

// Action Button Color variables
@orange-btn: #ffa500;
@red-btn: #f10000;
@blue-btn: #0f01ff;

// Main Header Logo Variables
@logo-height: 42px;

// Section Variables
@section-padding: 50px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 1200px;
@screen-mxl: 1400px;
@screen-xxl: 1600px;
@screen-bxl: 1800px;

@hight-screen-md: 800px;

.themeColorOpacity(@rgbColor, @opacity) {
  background-color: rgba(@rgbColor, @opacity);
}

// dashboard colors 
@pink-color: #FF4FBD;
@yellow-color: #FFBC58;
@green-color :#008977;
@orange-color: #FF6833;
@purple-color: #6E7EDF;