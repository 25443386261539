.main__page__appheader {
  display: flex;
  align-items: center;
  padding-inline: inherit !important;
  padding: 5px 30px !important;
  line-height: normal;
  background-color: @layout-body-background !important;
  border-bottom: 1px solid #e8e8e8;
  // box-shadow: 0px 2px 10px -6px rgb(0 0 0 / 75%);
  // box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  .headerWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .appheader__right {
    margin-left: auto;
    .userDropdown {
      .userMenuWrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        > span {
          margin-left: 10px;
        }
        .avatar img {
          width: 40px;
          height: 40px;
          background-color: transparent;
          border-radius: 8px;
        }
        .userName {
          font-weight: 500;
        }
        .anticon {
          font-size: 12px;
        }
      }
    }
  }

  .appheader__left {
    display: flex;
    align-items: center;
    .menuToggle {
      .icon {
        width: 42px;
        height: 42px;
        background-color: @white;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        border-radius: 8px;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        span {
          background-color: @text_dark_black;
          height: 2px;
          position: absolute;
          left: 11px;
          transition: all 0.5s ease;
          &:first-child {
            width: 20px;
            top: 14px;
          }
          &:nth-child(2) {
            width: 15px;
            top: 20px;
          }
          &:last-child {
            width: 10px;
            top: 26px;
          }
        }
        &.right span {
          right: 11px;
          left: auto;
        }
      }
    }
    > div {
      margin-right: 20px;
    }
    .syncMenu button {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 0;
      color: @white;
      background-color: @primary-color;
      border: 1px solid @primary-color;
      font-size: 16px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: @white;
        }
      }
    }
    .adminDropdown {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        background-color: @white;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .sidebar__trigger {
    cursor: pointer;
  }
}
