.dashboardCol {
  .dashboardCards {
    border: 0.1px solid @lite_grey;
    border-radius: 0.8rem;
    color: @primary-color;
    background-color: @white;
    padding: 3rem;
    position: relative;
    isolation: isolate;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      height: 0.5rem;
      width: 100%;
      transition: 0.3s all linear;
      background-color: currentColor;
    }

    .iconBox {
      width: 10rem;
      height: 10rem;
      border-radius: 10rem;
      padding: 2.5rem;
      position: relative;
      margin-bottom: 4rem;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: currentColor;
        opacity: 0.1;
      }

      svg {
        height: 5rem;
        width: 5rem;
        fill: currentColor;
        color: currentColor;

        path {
          fill: currentColor;
          color: currentColor;
        }

      }

    }

    .cardTitle {
      color: @grey;
      font-size: 1.9rem;
      font-weight: 500;
      margin: 0;
      transition: 0.3s all linear;
    }

    .iconBg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      height: 100%;
      width: auto;
      text-align: right;
      opacity: 0.03;

      svg {
        width: 98%;
        height: 98%;
        fill: currentColor;
        color: currentColor;

        path {
          fill: currentColor;
          color: currentColor;
        }
      }
    }

    .rightArrow {
      text-align: right;

      svg {
        width: 4rem;
        height: 4rem;
        fill: currentColor;
        color: currentColor;

        path {
          fill: currentColor;
          color: currentColor;
        }
      }
    }

    &:is(:hover, :focus-within,:focus) {
      .cardTitle {
        transform: translateY(-0.5rem);
      }

      &::after {
        bottom: 0;
      }
    }

  }

  &:nth-child(2n) {
    .dashboardCards {
      color: @pink-color;
    }
  }

  &:nth-child(3n) {
    .dashboardCards {
      color: @yellow-color;
    }
  }

  &:nth-child(4n) {
    .dashboardCards {
      color: @green-color;
    }
  }

  &:nth-child(5n) {
    .dashboardCards {
      color: @orange-color;
    }
  }

  @media (max-width: @screen-mxl) {
    .dashboardCards {
      .cardTitle {
        font-size: 1.6rem;
      }

      .iconBox {
        width: 7rem;
        height: 7rem;
        padding: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rightArrow {
        svg {
          height: 3rem;
          width: 3rem;
        }
      }
    }
  }
}