.loginSection {
	background-size: cover;
	background-position: left center;
	background-repeat: no-repeat;
	min-height: 100%;
	position: relative;

	.loginFormBigWrap {
		max-width: 60rem;
		border-radius: 20px;
		background: rgba(255, 255, 255, 0.50);
		backdrop-filter: blur(4px);
		padding: 3rem;
	}

	.languageList {
		max-width: 60rem;
		margin-bottom: 5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		// color: @white;
	}

	.userMenuWrap {
		border-radius: 40px;
		border: 1px solid rgba(244, 114, 22, 0.5);
		// background: rgba(244, 114, 22, 0.4);
		.themeColorOpacity(@language-dropdown, 0.4);

		max-width: 100%;
		text-align: center;
		min-width: 125px;
		padding: 1rem 2rem;
		transition: 0.3s all ease-in-out;

		&:hover {
			cursor: pointer;
			.themeColorOpacity(@language-dropdown, 0.7);
		}
	}

	.loginLocationContent {
		border-radius: 50px;
		border: 2px solid #FFF;
		background: #F47216;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.30) inset;
		font-family: @inter-font !important;
		margin: 3rem auto 0;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		color: @white;
		padding: 1rem 0;
		text-align: center;
		width: 100%;
		max-width: 46rem;

	}

	.loginTitle {
		font-size: 2rem;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		color: @white;
		border-radius: 50px;
		border: 2px solid #FFF;
		background: #F47216;
		max-width: 20rem;
		display: block;
		text-align: center;
		margin: 2rem;
		width: 100%;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.30) inset;
	}

	.loginWrap {
		position: absolute;
		right: 6rem;
		top: 2rem;
		min-width: 60rem;
	}

	.formTitle {
		color: #5E5E5E;
		font-size: 1.8rem;
		font-weight: 400;
		margin-bottom: 1rem;

		span {
			display: block;
			font-size: 4rem;
		}
	}

	.loginBtn {
		width: 100%;
	}

	.login_extra_fields {
		margin: -1rem 0 2rem 0;

		.forgotPass,
		span {
			color: @primary-color;
		}
	}

	.forgotPassLink {
		text-align: center;
		margin-top: 1.5rem;

		a {
			color: @primary-color;
		}
	}

	@media (max-width:@screen-xxl) {
		.loginFormBigWrap {
			padding: 1.5rem
		}
	}

	@media (max-width:@screen-lg) {
		.loginWrap {
			position: initial;
			min-width: auto;
			margin-top: 10rem;
		}

		.languageList {
			justify-content: center;
			max-width: 100%;
		}

		.loginFormBigWrap {
			margin: 0 auto;
		}

		.loginLogo {
			text-align: center;
		}

		.loginTitle {
			margin: 2rem auto;
		}
	}
}